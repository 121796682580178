<template>
    <b-modal id="register-user" title="Register User">
        <b-container fluid>
            <form @submit.prevent="register">
                <div class="form-group">
                    <label for="register-name">Name</label>
                    <input v-model="name" class="form-control" :class="{ 'is-invalid': nameErrors.length }"  id="register-name">
                    <div class="invalid-feedback" v-for="(text, i) in nameErrors" :key="i">{{ text }}</div>
                </div>
                <div class="form-group">
                    <label for="register-email">Email</label>
                    <input v-model="email" class="form-control" :class="{ 'is-invalid': emailErrors.length }"  id="register-email">
                    <div class="invalid-feedback" v-for="(text, i) in emailErrors" :key="i">{{ text }}</div>
                </div>
                <div class="form-group">
                    <b-form-group label="User role">
                        <b-form-radio v-model="role"  value="3">Operator</b-form-radio>
                        <b-form-radio v-model="role"  value="2">Admin</b-form-radio>
                    </b-form-group>
                </div>
                <div class="form-group">
                    <label for="register-password">Password</label>
                    <input type="password" v-model="password" class="form-control" :class="{ 'is-invalid': passwordErrors.length }"  id="register-password">
                    <div class="invalid-feedback" v-for="(text, i) in passwordErrors" :key="i">{{ text }}</div>
                </div>
                <div class="form-group">
                    <label for="register-confirmation-password">Confirm password</label>
                    <input type="password" v-model="password_confirmation" class="form-control" :class="{ 'is-invalid': passwordErrors.length }"  id="register-confirmation-password">
                    <div class="invalid-feedback" v-for="(text, i) in passwordErrors" :key="i">{{ text }}</div>
                </div>
            </form>
        </b-container>
        <template v-slot:modal-footer="{ cancel }">
            <b-button variant="primary" @click="register">Save</b-button>
            <b-button  @click="cancel()">Cancel</b-button>
        </template>
    </b-modal>  
</template>

<script>

export default {
    data() {
        return {
            name: '',
            email: '',
            role: 3,
            password: '',
            password_confirmation: '',
            has_error: false,
            error: '',
            errors: {},
            success: false,

            nameErrors: [],
            emailErrors: [],
            passwordErrors: []
        }
    },
    methods: {
        show() {
            this.name = null;
            this.email = null;
            this.role = 3,
            this.password = null;
            this.password_confirmation = null;
          
            this.nameErrors = [];
            this.emailErrors = [];
            this.passwordErrors = [];
            this.password_confirmationErrors = [];

            this.$bvModal.show('register-user');
        },
        register() {
            this.$http.post('auth/register', {
                name: this.name,
                email: this.email,
                role: this.role,
                password: this.password,
                password_confirmation: this.password_confirmation
            }).then(this.hide, this.responseError);
        },
        responseError(res) {
            this.nameErrors = this.getValidationError(res, 'name');
            this.emailErrors = this.getValidationError(res, 'email');
            this.passwordErrors = this.getValidationError(res, 'password');
        },
        hide() {
            this.$bvModal.hide('register-user');
            this.$emit('saved', true);
        },
        getValidationError(errorResp, errorSlug) {
            const data = errorResp.response.data;

            return data.hasOwnProperty('errors') && data.errors.hasOwnProperty(errorSlug) ? data.errors[errorSlug] : [];
        }
    }
}
</script>